<template>
  <div>
    <div class="green-section--mob">
      <GreenBlock>
        <v-container v-if="!loading">
          <h1 class="page-title">Guten tag, {{ data.user.Anrede + ' ' + data.user.Nachname }}</h1>
          <div class="green-section__content">
            <div class="green-section--left">
              <div class="page-subtitle reg">Mit dem SmartMeter-Angebot werden Zählerstände in Zukunft automatisch
                gemeldet. <br>
                Zusätzlich erhalten Sie Zugriff auf weitere Informationen und zukünftige smarte Funktionen.
              </div>
            </div>
          </div>
        </v-container>
      </GreenBlock>
      <v-container v-if="!loading">
        <div class="box-shadow box-shadow-small">
          <h3 class="section__title">SmartMeter</h3>
          <!--          <p>Bestellen Sie SmartMeter</p>-->
          <div class="smartmeter-block">
            <div class="smartmeter-block--description">
              <p>Der elektronische Drehstromzähler MT631 ist ein FNN Basiszähler in 3-Punkt Montage für den kommenden
                Rollout von
                Elektrizitätszählern und Messsystemen auf Grundlage des Messstellenbetriebsgesetzes (MsbG). </p>
              <p>Der MT631 mit integrierter Hutschiene ist eine perfekte Kombination aus bewährter Messtechnik im
                Zusammenspiel mit
                aktuellen Verschlüsselungsverfahren und erfüllt als FNN Basiszähler die Anforderungen des
                BSI an eine gesicherte Kommunikation mit dem SmartMeter Gateway (SMGw).</p>
              <ul class="list-checkmark">
                <li>moderne Messeinrichtung</li>
                <li>Historische Verbrauchs- und Einspeisewerte für die letzten 24 Monate</li>
                <li>Doppeltarif-Funktion (optional)</li>
                <li>Netzzustandsdaten (optional)</li>
                <li>Mit SmartMeter Gateway zum intelligenten Messsystem erweiterbar</li>
                <li>Datensicherheit nach BSI</li>
                <li>RS-485 LMN-Schnittstelle</li>
              </ul>
              <a href="#" v-scroll-to="'#angebot-scroll-to'" class="btn smartmeter-buy">Zum Angebot</a>
            </div>
            <div class="smartmeter-block--image">
              <img src="@/assets/images/smartmeter.png" alt="">
            </div>
          </div>
          <hr>
          <v-row class="angebot__section">
            <v-col sm="9">
              <v-col sm="8" class="py-0 px-0">
                <h3 class="section__title" id="angebot-scroll-to">Unser Angebot für Sie </h3>
              </v-col>
              <v-col sm="11" class="py-0 px-0">
                <p>eprimo testet derzeit, ob wir mithilfe eines modernen Stromzählers die Strompakete noch attraktiver
                  und einfacher gestalten können und bietet daher zu den bestehenden Strompaketen exklusiv für eine
                  kleine
                  Testgruppe und einen befristeten Zeitraum kostenfrei einen neuen, modernen und smarten Stromzähler
                  an.</p>
              </v-col>
              <p class="font-weight-bold">Ihre Vorteile im Überblick:</p>
              <ul class="list-checkmark mb-0">
                <li>Die regelmäßige Ablesung Ihres Zählers und der Termin zur Ablesung durch den Stromableser
                  entfällt.
                </li>
                <li>Sie erhalten regelmäßig eine Übersicht über Ihren Stromverbrauch.</li>
                <li>Sie nehmen aktiv an der Erneuerung unserer Stromnetze teil.</li>
                <li>Sie erhalten in Zukunft Zugriff auf weitere smarte Services.</li>
              </ul>
              <p class="font-weight-bold">Jetzt als befristetes Vorteilsangebot für Sie komplett kostenlos:</p>
              <ul class="list-checkmark mb-0">
                <li>kostenloser SmartMeter</li>
                <li>kostenloser Einbau des SmartMeters</li>
                <li>Entgelte für die Messung werden von eprimo übernommen.</li>
                <li>Entgelte für den digitalen Messstellenbetrieb sind bereits in Ihrem Strompaket enthalten.</li>
              </ul>
              <p>Bestellen Sie jetzt das zurzeit kostenlose eprimo SmartMeter-Angebot. Wir übernehmen als
                wettbewerblicher
                Messstellenbetreiber Ihren Messstellenbetrieb und Sie müssen keine Zählerstände mehr melden. Im nächsten
                Schritt kontaktieren wir Sie kurzfristig und vereinbaren einen Termin zum Austausch Ihres alten
                Stromzählers.</p>
            </v-col>
            <v-col sm="3" class="text-center">
              <img src="@/assets/images/icons/smartmeter.svg" alt="smartmeter">
            </v-col>
          </v-row>
          <hr>
          <form class="package-form">
            <h3 class="section__title mb-5">Kundendaten</h3>
            <div class="user-info-rwd">
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Anrede</label>
                  <div class="form-group__output w-600">{{ data.user.Anrede }}</div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Vorname</label>
                  <div class="form-group__output w-600">{{ data.user.Vorname }}</div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Nachname</label>
                  <div class="form-group__output w-600">{{ data.user.Nachname }}</div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Adresse</label>
                  <div class="form-group__output w-600">{{ data.user.Strasse + ' ' + data.user.Hausnr }}
                    {{ data.user.Hausnr_Ergaenzung }}
                  </div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Postleitzahl</label>
                  <div class="form-group__output w-600">{{ data.user.PLZ }}</div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Ort</label>
                  <div class="form-group__output w-600">{{ data.user.Ort }}</div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Zählernummer</label>
                  <div class="form-group__output w-600">{{ data.user.MeterID }}</div>
                </div>
              </div>
              <div class="package-form__item">
                <div class="package-form__group">
                  <label class="form-group__label">Kundennummer</label>
                  <div class="form-group__output w-600">{{ data.user.VK }}</div>
                </div>
              </div>
            </div>
            <hr>
            <h3 class="not-show-now section__title">Zahlungsdaten</h3>
            <div class="not-show-now package-form__item mt-3">
              <div class="package-form__group">
                <label class="form-group__label input-label">Zahlung</label>
                <div class="form-group__output form-group__packet">
                  <v-radio-group v-model="fields.payment">
                    <div class="package-form-item">
                      <v-radio
                          value="Einmalzahlung"
                      ></v-radio>
                      <div class="form-package package">
                        <div class="package__box">
                          <!--                        <div class="package__discount" v-if="selectedPackage.DiscountOTP > 0">-->
                          <!--                          <div>-{{ selectedPackage.DiscountOTP }}%</div>-->
                          <!--                        </div>-->
                          <div class="package__discount">
                            <div>-3%</div>
                          </div>
                          <h1 class="package__title">Einmalzahlung</h1>
                          <div class="package__price mt-0 mb-4">
                            <!--                          <p class="price">{{ formatPrice(selectedPackage.PriceOTP) }} €</p>-->
                            <!--                          <div>Einmalpreis für Paket<br>-->
                            <!--                            <span>(bereits rabbattiert, {{ selectedPackage.DiscountOTP }}% Skonto)</span>-->
                            <!--                          </div>-->
                            <p class="price">{{ formatPrice(this.data.user.SM_PriceOTP, false) }} €</p>
                            <div>Einmalpreis für SmartMeter<br>
                              <span>(bereits rabbattiert)</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="package-form-item">
                      <v-radio
                          value="Monatliche"
                      ></v-radio>
                      <div class="form-package package">
                        <div class="package__box">
                          <h1 class="package__title">Monatliche Raten</h1>
                          <div class="package__price mt-0 mb-4">
                            <!--                          <p class="price">{{ formatPrice(selectedPackage.PriceMonthly) }} €<sup>1</sup></p>-->
                            <!--                          <div>-->
                            <!--                            Gesamtpreis:-->
                            <!--                            {{ formatPrice(selectedPackage.EstimatedDuration * selectedPackage.PriceMonthly) }} €<br>-->
                            <!--                          </div>-->
                            <p class="price">{{ formatPrice(this.data.user.SM_PriceMonthly, false) }} €</p>
                            <div>Gesamtpreis: {{ formatPrice(this.data.user.SM_PriceMonthly, true) }} €</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-radio-group>
                  <!--                <div class="explanation-text mb-2">-->
                  <!--                  <p><sup>1</sup> Sollten Sie sich für eine Ratenzahlung entscheiden, wird Ihnen eine-->
                  <!--                    Ratenzahlungsvereinbarung mit einem Ratenzahlungsplan in Ihr mein eprimo Postfach übersandt, aus dem-->
                  <!--                    Sie die jeweiligen Fälligkeiten entnehmen können. Die Gewährung der Ratenzahlung erfolgt zinsfrei-->
                  <!--                    über die Anzahl von Raten. Weitere Details entnehmen Sie bitte den hier einsehbaren <a-->
                  <!--                        :href="`${publicPath}agb_Ergaenzende_Bedingungen_Strompakete_20200901.pdf`" target="_blank">Strompakete-AGB</a>.-->
                  <!--                  </p>-->
                  <!--                </div>-->
                </div>
              </div>
            </div>
            <!--          <div class="package-form__item mt-3">-->
            <!--            <div class="package-form__group">-->
            <!--              <label class="form-group__label input-label">Zahlung per</label>-->
            <!--              <div class="form-group__output">-->
            <!--                <div class="can-toggle can-toggle&#45;&#45;size-large">-->
            <!--                  <input id="payment" type="checkbox" v-model="fields.paymentBy">-->
            <!--                  <label for="payment">-->
            <!--                    <div class="can-toggle__switch" data-checked="Überweisung" data-unchecked="Lastschrift"></div>-->
            <!--                  </label>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div v-if="!fields.paymentBy">-->
            <!--            <div class="package-form__item">-->
            <!--              <div class="package-form__group">-->
            <!--                <label class="form-group__label input-label">Kontoinhaber</label>-->
            <!--                <div class="form-group__output">-->
            <!--                  <v-text-field-->
            <!--                      v-model="fields.accountOwner.value"-->
            <!--                      :class="{'v-valid':fields.accountOwner.valid}"-->
            <!--                      :error-messages="fields.accountOwner.error"-->
            <!--                      outlined-->
            <!--                  ></v-text-field>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="package-form__item">-->
            <!--              <div class="package-form__group">-->
            <!--                <label class="form-group__label input-label">IBAN</label>-->
            <!--                <div class="form-group__output">-->
            <!--                  <v-text-field-->
            <!--                      v-model="fields.iban.value"-->
            <!--                      :class="{'v-valid':fields.iban.valid}"-->
            <!--                      :error-messages="fields.iban.error"-->
            <!--                      outlined-->
            <!--                  ></v-text-field>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="package-form__item">-->
            <!--              <div class="package-form__group">-->
            <!--                <label class="form-group__label"></label>-->
            <!--                <div class="form-group__output">-->
            <!--                  <v-checkbox-->
            <!--                      class="small-checkbox"-->
            <!--                      v-model="fields.debit.value"-->
            <!--                      :class="{'v-valid':fields.debit.valid}"-->
            <!--                      label="Ich ermächtige die eprimo GmbH (Gläubiger-Identifikationsnummer DE12ZZZ00000080651) widerruflich, fällige Beträge per Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von eprimo GmbH auf mein Konto gezogenen Lastschriften einzulösen.-->
            <!--Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen.-->
            <!--Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen."-->
            <!--                      :error-messages="fields.debit.error"-->
            <!--                  ></v-checkbox>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <div class="package-form__legal-info mb-3">
              <div>
                <h3 class="section__title">Rechtliche Information</h3>
                <div class="package-form__links">
                  <!--                  <div>-->
                  <!--                    <a href="https://www.eprimo.de/ueber-eprimo/agb/" target="_blank"><i-->
                  <!--                        class="epicon epicon-arrow-edgy-right"/>AGB</a> &-->
                  <!--                    <a :href="`${publicPath}agb_Ergaenzende_Bedingungen_Strompakete_20200901.pdf`" target="_blank">Strompakete-->
                  <!--                      AGB</a>-->
                  <!--                  </div>-->
                  <div>
                    <i class="epicon epicon-arrow-edgy-right"/>
                    <a :href="`${publicPath}agb_wMSB_151120.pdf`" target="_blank">wMSB AGB</a>
                  </div>
                  <div><a href="https://www.eprimo.de/ueber-eprimo/datenschutzerklaerung" target="_blank"><i
                      class="epicon epicon-arrow-edgy-right"/>Datenschutzinformation</a></div>
                  <div><span @click.stop="dialogServise = true"><i
                      class="epicon epicon-arrow-edgy-right"/>Kundenservice</span>
                    <v-dialog
                        v-model="dialogServise"
                        scrollable
                    >
                      <v-card>
                        <v-card-title>
                          <div class="h1">Kundenbetreuung und Kundenbeschwerden</div>
                          <button class="epicon epicon-close" @click="dialogServise = false"></button>
                        </v-card-title>
                        <v-card-text>
                          <div class="modal-text" id="kundenbetreuung">
                            <h2 class="h1">Kundenbetreuung und Kundenbeschwerden</h2>
                            <p>Für evtl. Beanstandungen stehen Ihnen die u.a. Kontaktwege zur Verfügung. eprimo wird
                              Ihre
                              Beanstandung binnen einer Frist von 4 Wochen beantworten.</p>
                            <p>Schriftlich: eprimo GmbH, Abteilung Kundenservice, Flughafenstr. 20, 63263
                              Neu-Isenburg</p>
                            <p>Telefonisch: Service-Hotline 0800/60 60 110 (kostenlos aus dem dt. Festnetz), für Anrufe
                              aus einem Mobilfunknetz: 069/80 88 12 34 (es entstehen die gemäß Ihrem Mobilfunkvertrag
                              üblichen Verbindungskosten für Anrufe ins dt. Festnetz)</p>
                            <p>Email: <a href="mailto:kundenservice@eprimo.de">kundenservice@eprimo.de</a></p>
                            <p>Sollte Ihrer Beanstandung nicht innerhalb der unter Ziffer 20.1 der Allgemeinen
                              Geschäftsbedingungen benannten Frist abgeholfen werden, können Sie sich unter den
                              Voraussetzungen des § 111b EnWG an die Schlichtungsstelle Energie e.V., Friedrichstraße
                              133,
                              10117 Berlin, <a href="http://www.schlichtungsstelle-energie.de" target="_blank">www.schlichtungsstelle-energie.de</a>,
                              <a href="mailto:info@schlichtungsstelle-energie.de">info@schlichtungsstelle-energie.de</a>,
                              030/27 57 240-0 wenden. eprimo ist zur Teilnahme an dem Streitbeilegungsverfahren
                              gesetzlich
                              verpflichtet. Das Recht der Beteiligten, die Gerichte anzurufen oder ein anderes Verfahren
                              nach dem EnWG zu beantragen, bleibt unberührt. Durch ein etwaiges Schlichtungsverfahren
                              wird
                              die Verjährung gemäß § 204 Abs. 1 Nr.4 BGB gehemmt.</p>
                          </div>
                          <button class="link-arrow" @click="printModal('kundenbetreuung')"><i
                              class="epicon epicon-arrow-edgy-right"></i>Drucken
                          </button>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div><span @click.stop="dialogRightWithdrawal = true"><i class="epicon epicon-arrow-edgy-right"/>Widerufsrecht</span>
                    <v-dialog
                        v-model="dialogRightWithdrawal"
                        scrollable
                    >
                      <v-card>
                        <v-card-title>
                          <div class="h1">Widerrufsbelehrung</div>
                          <button class="epicon epicon-close" @click="dialogRightWithdrawal = false"></button>
                        </v-card-title>
                        <v-card-text>
                          <div class="modal-text" id="widerrufsbelehrung">
                            <div class="h1">Widerrufsbelehrung</div>
                            <p>Widerrufsrecht</p>
                            <p>
                              Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                              widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um
                              Ihr Widerrufsrecht auszuüben, müssen Sie uns (eprimo GmbH, Flughafenstraße 20, 63263
                              Neu-Isenburg, Telefon: 069 / 697670-0, Fax: 069 / 697670-111, E-Mail:
                              <a href="mailto:kundenservice@eprimo.de">kundenservice@eprimo.de</a>) mittels einer
                              eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über
                              Ihren
                              Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das
                              <a
                                  href="https://content.eprimo.de/fileadmin/user_upload/AGB_PDFs/EPRIMO-Son-17001_Widerrufs_AS.pdf"
                                  target="_blank"
                              >
                                Muster-Widerrufsformular
                              </a> verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht
                              es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                              Widerrufsfrist absenden.
                            </p>
                            <p>Widerrufsfolgen</p>
                            <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
                              erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
                              sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
                              günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn
                              Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags
                              bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
                              Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
                              ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
                              Rückzahlung Entgelte berechnet.</p>
                          </div>
                          <button class="link-arrow" @click="printModal('widerrufsbelehrung')"><i
                              class="epicon epicon-arrow-edgy-right"></i>Drucken
                          </button>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div><span @click.stop="InformationContractPopup = true"><i class="epicon epicon-arrow-edgy-right"/>Informationen zum Vertragabschluss</span>
                    <v-dialog
                        v-model="InformationContractPopup"
                        scrollable
                    >
                      <v-card>
                        <v-card-title>
                          <div class="h1">Informationen im elektronischen Geschäftsverkehr</div>
                          <button class="epicon epicon-close" @click="InformationContractPopup = false"></button>
                        </v-card-title>
                        <v-card-text>
                          <div class="modal-text" id="informationen">
                            <div class="h1">Informationen im elektronischen Geschäftsverkehr</div>
                            <h2>1. Technische Schritte zum Vertragsschluss, Eingabefehler</h2>
                            <p>Nachdem Sie in unserem Tarifrechner einen günstigen Tarif ausgewählt haben (Button „Tarif
                              auswählen“), startet der schnelle und einfache Bestellprozess. Sie werden in weiteren
                              Schritten zur Eingabe Ihrer Daten und der erforderlichen Informationen für die Einleitung
                              des Wechselprozesses aufgefordert. Vor dem Absenden Ihrer Bestellung können Sie auf der
                              Bestellübersichtsseite Ihre Angaben prüfen und über die Links „ändern“ mögliche
                              Eingabefehler berichtigen.</p>
                            <p>Mit der Absendung Ihrer Bestellung über den Button „zahlungspflichtig bestellen“ geben
                              Sie
                              eine verbindliche Vertragserklärung ab. Die Bestätigung des Eingangs der Bestellung, folgt
                              unmittelbar nach dem technisch einwandfreien Eingang Ihrer Bestellung. Diese
                              Eingangsbestätigung stellt noch keine Annahme Ihrer Vertragserklärung dar, sondern soll
                              Sie
                              nur darüber informieren, dass Ihre Bestellung bei uns eingegangen ist und kann für
                              Archivierungszwecke verwendet werden. Das Zustandekommen eines verbindlichen Vertrages
                              bestimmt sich nach Ziff. 3 unserer
                              <a href="https://www.eprimo.de/ueber-eprimo/agb/">AGB</a>.</p>
                            <h2>2. Speicherung Vertragstext, Vertragssprache</h2>
                            <p>Unsere AGB, Datenschutzinformation und die weiteren Vertragsbestimmungen mit den Daten
                              Ihrer Bestellung, halten wir auf unseren Bestellseiten zum Abruf bereit. Es besteht dort
                              die
                              Möglichkeit die Daten als PDF herunterzuladen oder über die Funktionen des von Ihnen
                              genutzten Browsers zu speichern.</p>
                            <p> Darüber hinaus lassen wir Ihnen die Vertragsbestimmungen noch einmal im Nachgang zu
                              Ihrer
                              Bestellung zukommen, per Post oder sofern Sie sich dort anmelden, in unserem Kundenportal
                              „mein eprimo“.
                              Darüber hinaus wird der Vertragstext bei uns gespeichert, ist aber aus Sicherheitsgründen,
                              soweit Sie sich nicht für unser Kundenportal „mein eprimo“ registriert haben, nicht
                              unmittelbar von Ihnen abrufbar. Über unser Kundeportal „mein eprimo“ haben Sie mittels
                              eines
                              passwortgeschützten direkten Zugangs die Möglichkeit, Ihre Daten zu verwalten. Die AGB
                              finden Sie in der jeweils gültigen Fassung auf unserer Webseite www.eprimo.de.
                              Verträge auf unserer Seite www.eprimo.de lassen sich derzeit nur in deutscher Sprache
                              schließen.</p>
                            <h2>3. Verhaltenskodizes der eprimo GmbH</h2>
                            <p>Die eprimo GmbH, als Teil der innogy SE, diese wiederum Teil der E.ON SE und der damit
                              verbundenen Unternehmen, unterwirft sich dem Verhaltenskodex des E.ON Konzerns. Diesen
                              Kodex
                              finden Sie im Internet unter: www.eon.com/verhaltenskodex.</p>
                          </div>
                          <button class="link-arrow" @click="printModal('informationen')"><i
                              class="epicon epicon-arrow-edgy-right"></i>Drucken
                          </button>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <v-checkbox
                      class="mt-4 ml-2 checkbox--margin accept-term-box"
                      v-model="fields.optin.value"
                      :class="{'v-valid':fields.optin.valid}"

                      label="Ich habe die wMSB AGB zur Kenntnis genommen und stimme diesen zu. Ebenfalls habe ich die Widerrufsbelehrung und die Hinweise auf Beschwerdemöglichkeiten zur Kenntnis genommen."
                      :error-messages="fields.optin.error"
                  ></v-checkbox>
                  <v-checkbox
                      class="ml-2 checkbox--margin"
                      v-model="fields.optin2.value"
                      :class="{'v-valid':fields.optin2.valid}"

                      label="Hiermit beauftrage ich die eprimo GmbH mit der Übernahme des Messstellenbetriebs an meiner Lieferadresse und bevollmächtige eprimo zur Kündigung meines Vertrags mit meinem derzeitigen Messstellenbetreiber für diese Messlokation."
                      :error-messages="fields.optin2.error"
                  ></v-checkbox>
                </div>
                <!--              <div class="fz-12 ml-2">-->
                <!--                Wenn Sie sich dazu entscheiden, dieses Angebot zu nutzen verpflichten Sie sich, monatliche-->
                <!--                Zählerstände an eprimo zu übermitteln. Eine Erinnerung übersenden wir an Ihre oben-->
                <!--                genannte E-Mail-Adresse.-->
                <!--              </div>-->
              </div>
              <div class="package-form-box">
                <h3>Haben Sie Fragen?</h3>
                <div class="epicon epicon-service"></div>
                <div class="w-700 mb-2">Rufen Sie uns an:<br>
                  0800-204011093
                </div>
                <div class="fz-12">Diese Rufnummer ist aus dem deutschen Festnetz kostenlos.
                  Unsere Service-Zeiten sind montags – freitags von 9.00 – 18.00 Uhr.
                </div>
              </div>
            </div>

            <div class="text-center">
              <v-btn class="btn mb-3" @click="validate">Jetzt kostenlos bestellen</v-btn>
            </div>
          </form>
        </div>
      </v-container>
    </div>
    <FAQ/>
  </div>
</template>

<script>
import FAQ from '@/components/FAQSmartOrder';
import GreenBlock from '@/components/GreenBlock';

export default {
  components: {
    FAQ,
    GreenBlock
  },
  props: ['userData', 'selectedPackage', 'lastPackage'],
  data() {
    return {
      token: this.$route.params.token,
      loading: true,
      data: null,
      publicPath: process.env.BASE_URL,
      dialogServise: false,
      dialogRightWithdrawal: false,
      InformationContractPopup: false,
      fields: {
        payment: 'Einmalzahlung',
        paymentBy: false,
        iban: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        accountOwner: {
          value: '',
          error: '',
          valid: false,
          visible: true
        },
        debit: {
          value: false,
          error: '',
          valid: false,
          visible: true,
          type: 'checkbox'
        },
        optin: {
          value: false,
          error: '',
          valid: false,
          visible: true,
          type: 'checkbox'
        },
        optin2: {
          value: false,
          error: '',
          valid: false,
          visible: true,
          type: 'checkbox'
        }
      },
      validateFields: [
        // 'iban',
        // 'debit',
        'optin',
        'optin2'
      ]
    }
  },
  watch: {
    'fields.paymentBy'(val) {
      this.fields.accountOwner.visible = !val;
      this.fields.iban.visible = !val;
      this.fields.debit.visible = !val;
    }
  },
  mounted() {
    fetch(process.env.VUE_APP_BACKEND_BASE_URL + 'meter_order/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: this.token
      })
    })
        .then(res => res.json())
        .then(data => {
          this.loading = false;
          if (data.success) {
            this.data = data.user;
          } else {
            if (data.errorCode === 0) {
              this.$router.push({
                name: 'meter_ordering_login', params: {
                  uuidProp: this.token,
                  errorProp: true
                }
              });
            } else {
              this.$router.push({name: 'meter_ordering_error'});
            }
          }
        });
  },
  methods: {
    clearErrorMessages() {
      this.validateFields.forEach(field => this.fields[field].error = '');
    },
    validate() {
      const body = {};
      this.validateFields.forEach(f => {
            if (this.fields[f].visible) {
              body[f] = this.fields[f].value;
            }
          }
      );

      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'validate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
          .then(res => res.json())
          .then(json => {
            this.clearErrorMessages();
            if (Object.keys(json).length !== 0 || json.length) {
              for (let prop in json) {
                this.fields[prop].valid = false
                this.fields[prop].error = json[prop][0];
              }
              this.validateFields.forEach(item => {
                    if (this.fields[item].error === '') {
                      this.fields[item].valid = true
                      if (this.fields[item].type === 'checkbox') {
                        this.fields.emailConfirm.valid = true
                      }
                    }
                    // if (this.fields.email.valid === false) {
                    //   this.fields.emailConfirm.valid = false
                    // }
                  }
              );
            } else {
              this.submit();
            }
          });
    },
    submit: function () {
      const body = {
        token: this.token,
        paymentData: {
          Zahlung: this.fields.payment,
          Zahlung_Per: this.fields.paymentBy ? 'Überweisung' : 'Lastschrift'
        }
      };

      if (!this.fields.paymentBy) {
        body.paymentData.Kontoinhaber = this.fields.accountOwner.value;
        body.paymentData.IBAN = this.fields.iban.value;
        body.paymentData.Lastschriftmandat = this.fields.debit.value;
      }

      this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'meter_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      this.$router.push({
        name: 'meter_ordering_thanks'
      });
    },
    formatPrice(value, flag) {
      if (value) {
        let val = value.toString().replace(',', '.')
        if (flag) val = val * 12;
        return parseFloat(val).toFixed(2).replace('.', ',')
      }
    },
    printModal(id) {
      this.printElement(document.getElementById(id));
    },
    printElement(elem) {
      var domClone = elem.cloneNode(true);
      var $printSection = document.getElementById('printSection');

      if (!$printSection) {
        $printSection = document.createElement('div');
        $printSection.id = 'printSection';
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = '';
      $printSection.appendChild(domClone);
      window.print();
    }
  }
}
</script>

<style lang="scss" scoped>
.epicon-service {
  font-size: 50px;
  color: $green;
}

.green-section {
  padding: 20px 0 240px;
  margin-bottom: -230px;
}

.section__title {
  margin-bottom: 0px;
}

form .section__title {
  margin-bottom: 30px;
}

.smartmeter-block {
  display: flex;
}

.btn:not(.popover-email).smartmeter-buy {
  min-width: 290px;
  margin-top: 5px;
}

.v-input--checkbox div.v-input--selection-controls__input {
  padding-bottom: 3px;
}

.popover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

  &-email {
    margin-right: -22px;
    min-width: auto;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    height: 22px;
    color: $grey-light-3;
    border: none;
    background: transparent;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:focus,
    &:active,
    &:hover {
      color: $grey-light-3;
      background: transparent;
      border: none;
      box-shadow: none;
    }

    .epicon {
      font-size: 22px;
    }
  }
}

.link-arrow {
  color: $blue;
  font-size: 13px;

  .epicon {
    font-size: 13px;
    vertical-align: initial;
    margin-right: 0;
    margin-left: -6px;
  }
}

.container--margin {
  margin-top: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 6px 30px;
}

.v-btn.v-size--default {
  font-size: 18px;
}

.epicon-arrow-edgy-right {
  vertical-align: text-bottom;
  margin-right: 10px;
}

.fz-12 {
  font-size: 12px;
}

.packages {
  &-top {
    .package__title {
      padding: 9px 0;
    }
  }
}

.package {
  &__price {
    min-height: auto;
  }

  &__discount {
    right: -5px;
    top: -25px;
  }

  &-form {
    margin-top: 20px;

    &__links {
      margin-bottom: 0;

      > div {
        margin-bottom: 7px;
        color: $blue;

        span {
          cursor: pointer;
          color: $blue;
        }
      }
    }

    &-item {
      position: relative;
    }

    &-box {
      border-radius: 4px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      padding: 20px 30px;
      text-align: center;
      margin-right: 5px;

      h3 {
        color: $green;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
  min-width: 240px;
}

.w-600 {
  font-weight: 600;
}

.w-700 {
  font-weight: 700;
}

.smartmeter-block--description p {
  line-height: 1.4;
}

.btn-back {
  color: $blue;
}

.can-toggle {
  position: relative;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &:checked ~ label .can-toggle__switch:before {
      content: attr(data-unchecked);
      left: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:checked ~ label .can-toggle__switch:after {
      content: attr(data-checked);
    }

    &:checked ~ label .can-toggle__switch:after {
      transform: translate3d(100%, 0, 0);
    }
  }

  input[type=checkbox][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5);
    pointer-events: none;

    .can-toggle__switch {
      opacity: 0.4;
      background-color: #70c767;

      &:before {
        content: attr(data-unchecked);
        left: 0;
      }

      &:after {
        content: attr(data-checked);
        color: #4fb743;
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      height: 40px;
      flex: 0 0 134px;
      border-radius: 4px;
      position: relative;
      transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: $white;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        left: 67px;
        font-size: 12px;
        line-height: 38px;
        width: 67px;
        padding: 0 12px;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-align: center;
        background: $green;
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        color: $white;
      }
    }
  }

  &.can-toggle--size-large input[type=checkbox] {
    &:checked ~ label .can-toggle__switch:after {
      transform: translate3d(100%, 0, 0);
    }
  }

  &.can-toggle--size-large label {
    font-size: 14px;

    .can-toggle__switch {
      flex: 0 0 100%;
      border-radius: 4px;
      background-color: $green;
      overflow: hidden;
      font-weight: 600;
      margin-bottom: 5px;

      &:before {
        right: 1px;
        left: auto;
        top: 1px;
        font-size: 14px;
        line-height: 38px;
        width: 50%;
        padding: 0 12px;
        background: $white;
        color: $grey;
        border-radius: 4px;
      }

      &:after {
        top: 1px;
        left: 1px;
        border-radius: 2px;
        width: 50%;
        line-height: 38px;
      }
    }
  }
}

@media (max-width: 500px) {

  .btn:not(.popover-email) {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .btn-back {
    float: right;
  }

  .btn:not(.popover-email).smartmeter-buy {
    min-width: unset;
  }

  .smartmeter-block {
    flex-wrap: wrap;

    &--description {
      order: 2;
      width: 100%;
    }

    &--image {
      order: 1;
      margin-bottom: 10px;

      img {
        width: 86%;
      }
    }
  }

  .v-application .mt-3 {
    margin-top: 0 !important;

    label {
      margin-bottom: 15px;
    }
  }

  .package {
    &__bottom {
      overflow: hidden;
    }

    &-form {
      &-item:not(:last-of-type) {
        margin-bottom: 30px;
      }

      &__group {
        margin-bottom: 12px;
      }

      &-box {
        margin-top: 0;
      }
    }
  }

  .checkbox--margin {
    margin-right: 19px;
  }
}

@media (max-width: 340px) {
  .form-group__output,
  .form-group__label {
    font-size: 12px !important;
  }
}

@media (min-width: 768px) {
  .container--margin {
    margin-top: 50px;
  }

  .smartmeter-block--image {
    margin: -10px 20px 0;
  }

  .checkbox--margin {
    margin-right: 20px;
  }

  .list-checkmark {
    //display: flex;
    //justify-content: space-around;
    //flex-wrap: wrap;
    padding: 0;
    margin-bottom: 18px;

    li {
      margin-bottom: 5px;

    }
  }

  .package {
    &__bottom {
      position: relative;
      padding: 0 100px;
    }

    &-form {
      &__group {
        margin-bottom: 7px;
        position: relative;
        display: flex;
        align-items: flex-start;
      }

      &__legal-info {
        display: flex;
        justify-content: space-between;
      }

      &-box {
        min-width: 285px;
        max-width: 285px;
        margin-left: 40px;
      }
    }
  }

  .btn-back {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .package-form-item {
    width: 50%;
    max-width: 300px;
    margin: 0 17px;

    .package {
      height: 100%;

      &__box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

.smartmeter-block .list-checkmark li:before {
  color: #73C72B;
}
</style>

<style lang="scss">
.form {
  &-group {
    &__packet {
      .v-icon {
        display: none;
      }
    }

    &__output {
      small {
        font-size: 11px;
        display: block;
      }
    }
  }

  &-package {
    .package__price {
      min-height: 75px;
    }
  }
}

.package-form {
  .v-text-field input {
    color: $grey;
    max-height: initial;
    line-height: 22px;
    padding: 8px 20px;
    caret-color: $grey;
  }
}

.v-text-field__details {
  display: none;
}

.v-messages__message {
  line-height: 16px;
}

.v-input {
  > .v-input__control {
    > .v-input__slot {
      min-height: 40px;
      margin-bottom: 3px;
      padding: 0 !important;
    }
  }

  fieldset {
    border-color: $grey-light-2;
  }

  &.v-input--is-focused {
    fieldset {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(180, 218, 4, .5);
      border: 1px solid $green-1;
      outline: none;
    }

    &.error--text {
      fieldset {
        box-shadow: 0 0 0 0.2rem rgba(254, 188, 2, .25);
      }
    }
  }

  .v-input__control .v-text-field__slot,
  .v-input__control .v-label {
    position: relative;

    &:after {
      font-family: epicons;
      position: absolute;
      right: -40px;
      left: auto;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      width: initial;
    }
  }

  &.error--text {
    fieldset {
      border: 1px solid $orange;
    }

    .v-text-field__details {
      display: block;
      margin-bottom: 12px;
    }

    .v-text-field__slot,
    .v-label {
      &:after {
        content: "";
        display: block;
        color: $orange;
      }
    }

    .error--text {
      color: $orange !important;
      caret-color: $grey !important;
      font-size: 13px;
    }
  }

  &.v-valid {
    .v-text-field__slot,
    .v-label {
      &:after {
        content: "";
        display: block;
        color: $green;
      }
    }
  }
}

.v-input {
  font-size: 14px;
}

.v-input--selection-controls {
  .v-input__slot > .v-label {
    font-size: 12px;
    line-height: 1.3;
    color: $grey;

    &.error--text {
      color: $grey !important;
    }
  }

  .v-messages.error--text {
    color: $orange !important;
    margin-left: 30px;
    margin-bottom: 12px;
  }
}

.angebot__section {
  align-items: center;

  ul.list-checkmark {
    margin-bottom: 15px !important;
  }
}

.small-checkbox {
  padding-top: 10px;
}

.v-input--checkbox {
  div.v-input--selection-controls {
    &__input {
      margin-right: 12px;
      width: 18px;
      height: 18px;
    }
  }

  .v-input__slot {
    align-items: flex-start;
  }

  .mdi-checkbox-blank-outline::before {
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid $grey-light-2;
    border-radius: 4px;
  }

  .mdi-checkbox-blank-outline::after {
    display: none;
  }

  .v-input--selection-controls__ripple {
    display: none;
  }

  .mdi-checkbox-marked {
    &::before {
      content: '';
      border: 1px solid #ccc;
      width: 18px;
      border-radius: 4px;
      height: 18px;
    }

    &:after {
      content: "";
      font-family: 'epicons';
      position: absolute;
      left: 3px;
      top: 3px;
      font-size: 12px;
      color: #73c72b;
      border-radius: 0;
      transform: none;
      background-color: transparent;
      opacity: 1;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      transition: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.v-radio {
  ~ .package {
    .package__title {
      color: $grey;
      margin: 0 -5px;
    }
  }

  &.v-item--active {
    ~ .package {
      .package__box {
        box-shadow: 0 0 10px rgba(0, 0, 0, .27);
      }

      .package__price {
        background-color: $green;
      }

      .package__title {
        color: $green;
      }
    }
  }

  .v-input--selection-controls__input {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    input[role=radio] {
      max-height: initial;
      z-index: 1;
    }
  }
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0;
}

.v-input--selection-controls {
  margin: 0;

  &__ripple {
    display: none;
  }
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .green-section--mob {
    padding: 0 0 10px;
    background-color: $green-1;
    background-image: -webkit-gradient(linear, left top, left bottom, from($green), to(rgba(255, 255, 255, 0)));
    background-image: -o-linear-gradient(top, $green, rgba(255, 255, 255, 0));
    background-image: linear-gradient(180deg, $green, rgba(255, 255, 255, 0));
    background-size: cover;
    background-repeat: repeat-x;
    position: relative;
    margin-bottom: 30px;

    &:before {
      content: '';
      background-image: url('~@/assets/images/bg-curve-1.png');
      height: 3vw;
      position: absolute;
      bottom: -.0625rem;
      display: block;
      width: 100%;
      background-position: top;
      background-size: cover;
      z-index: 0;
    }
  }

  .green-section--mob .green-section {
    background-image: none;
    background-color: transparent;
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }

}

@media screen {
  #printSection {
    display: none;
  }

  .modal-text {
    .h1 {
      display: none;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
    height: 0;
    color: #000 !important;
    text-decoration: none !important;
  }
  #printSection, #printSection * {
    visibility: visible;
    height: auto;
  }

  #printSection {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 35px 0;
    margin-right: 50px;

    .h1 {
      display: block;
      font-size: 18px;
      margin-bottom: 12px;
    }

    h2 {
      font-size: 14px;
      margin-bottom: 12px;
    }

    p {
      font-size: 9px;
      margin-bottom: 12px;
    }
  }
}

.container .box-shadow-small {
  padding-top: 10px;
}

.page-title {
  font-size: 32px;
}

.page-subtitle.reg {
  font-size: 16px;
  font-weight: 400;
}

.user-info-rwd .package-form__group {
  margin-bottom: 7px;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.user-info-rwd .form-group {
  &__output {
    width: 70%;
  }

  &__label {
    width: 30%;
    text-align: right;
    padding-right: 35px;

    &.input-label {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      word-break: break-word;
      min-height: 40px;
      flex-direction: column;

      &.popover-label {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

@media (min-width: 768px) {
  .v-input--radio-group--column .v-input--radio-group__input {
    flex-direction: row;
    margin: 0 -17px;
  }

  .form-group {
    &__output {
      width: 72.5%;
    }

    &__label {
      width: 28.5%;
      text-align: right;
      padding-right: 35px;

      &.input-label {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        word-break: break-word;
        min-height: 40px;
        flex-direction: column;

        &.popover-label {
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot,
  .can-toggle,
  .v-text-field__details,
  .small-checkbox,
  .form-group__output small {
    max-width: 420px;
  }
}

.not-show-now {
  display: none;
}

.package-form__links {
  margin-top: 10px;

  .accept-term-box {
    margin-bottom: 0 !important;
  }

}

.package-form__legal-info {
  align-items: center;
}

@media (max-width: 767px) {

  .user-info-rwd .form-group {
    &__output {
      width: 50%;
    }

    &__label {
      width: 50%;
    }
  }

  .page-title {
    font-size: 20px;
  }

  .green-section__content .page-subtitle {
    font-size: 14px;
  }

  .form {
    &__output {
      width: 100%;
    }

    &-group__label {
      display: block;
    }
  }

  .package-form__item {
    padding-right: 0;
  }

  .v-input {
    &.error--text,
    &.v-valid {
      .v-text-field__slot,
      .v-label {
        &:after {
          right: -25px;
        }
      }
    }
  }
}
</style>
